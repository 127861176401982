import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Country, EnterpriseCompany } from 'src/app/models/company.model';
import { ConfirmationModalData } from 'src/app/models/modal.interface';
import { ConfirmationModal } from 'src/app/classes/modal.class';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { CountryCallingCode } from 'src/app/models/country-calling-codes.model';
import { ModalService } from 'src/app/services/modal.service';
import { LINKEDIN_PROFILE } from 'src/app/resources/linkedinProfile';
import { LINKEDIN_REGEX } from 'src/app/resources/regex-validators';

@Component({
  selector: 'app-candidate-register-form',
  templateUrl: './candidate-register-form.component.html',
  styleUrls: ['./candidate-register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateRegisterFormComponent implements OnInit {

  @HostBinding('class.route-card') card = true;

  @Input() candidateRegisterForm: FormGroup;
  @Input() termsAndConditions: string;
  // Universal job related input properties
  @Input() countryRelatedCompaniesWithUniversalJob?: EnterpriseCompany[] = [];
  @Input() countries?: Country[] = [];
  @Input() linkedinProfileValue: string;

  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() countryChanged?: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyChanged?: EventEmitter<void> = new EventEmitter<void>();

  countryCallingCodes: CountryCallingCode[];
  companyCountryCallingCodes: CountryCallingCode;

  phoneNumberMinLength: number;
  phoneNumberMaxLength: number;
  disableButton = false;

  constructor(
    private configService: AppConfigService,
    private candidateService: CandidateRegisterService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) { }

  get name(): FormControl {
    return this.candidateRegisterForm.get('name') as FormControl;
  }

  get email(): FormControl {
    return this.candidateRegisterForm.get('email') as FormControl;
  }

  get phone(): FormControl {
    return this.candidateRegisterForm.get('phone') as FormControl;
  }

  get networks(): FormControl {
    return this.candidateRegisterForm.get('networks') as FormControl;
  }

  get company(): FormControl {
    return this.candidateRegisterForm.get('company') as FormControl;
  }

  get country(): FormControl {
    return this.candidateRegisterForm.get('country') as FormControl;
  }

  get acceptTermsAndConditions(): FormControl {
    return this.candidateRegisterForm.get('acceptTermsAndConditions') as FormControl;
  }

  get linkedinProfile(): FormControl {
    return this.candidateRegisterForm.get('linkedinProfile') as FormControl;
  }

  ngOnInit(): void {
    this.getCountryCallingCodes();
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}) {
    if(this.linkedinProfileValue) {
      this.setLinkedInValidators();
    }
  }

  setLinkedInValidators() {
    if (this.linkedinProfileValue === LINKEDIN_PROFILE.MANDATORY) {
      this.linkedinProfile.addValidators([Validators.required]);
      this.linkedinProfile.addValidators([Validators.pattern(LINKEDIN_REGEX)]);
      this.linkedinProfile.updateValueAndValidity();
    } else {
      this.linkedinProfile.addValidators([Validators.pattern(LINKEDIN_REGEX)]);
      this.linkedinProfile.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    this.disableButton = true;
    if (!this.linkedinProfile.value) {
      this.linkedinProfile.patchValue(null);
    }

    if (this.linkedinProfile.value && !this.linkedinProfile.value.includes('https://')) {
      const linkedinValue = 'https://' + this.linkedinProfile.value
      this.linkedinProfile.patchValue(linkedinValue);
    }

    this.formSubmitted.emit();
  }

  getCountryCallingCodes(): void {
    this.candidateService
      .getCountryCallingCodes()
      .subscribe((countryCallingCodes: CountryCallingCode[]) => {
        this.countryCallingCodes = countryCallingCodes;

        const { organization } = this.configService.config;

        this.companyCountryCallingCodes = this.countryCallingCodes
          .find(({language}: CountryCallingCode) => language === organization.language);

        this.candidateRegisterForm.patchValue({networks: this.companyCountryCallingCodes});
        this.changedNetwork();
        this.cdr.detectChanges();
      });
  }

  changedNetwork(): void {
    this.phoneNumberMaxLength = this.networks.value.maxLength;
    this.phoneNumberMinLength = this.networks.value.minLength;

    this.phone.setValidators([
      Validators.minLength(this.phoneNumberMinLength),
      Validators.maxLength(this.phoneNumberMaxLength),
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$')
    ]);

    this.phone.updateValueAndValidity();
  }

  changedCountry(): void {
    this.countryChanged.emit();
  }

  changedCompany(): void {
    this.companyChanged.emit();
  }

  openTermsAndConditionsModal(): void {
    if (this.acceptTermsAndConditions.disabled) {
      return;
    }

    const data: ConfirmationModalData = {
      title: 'REGISTER.TOS',
      htmlContent: this.termsAndConditions,
      confirmBtnTitle: 'BUTTONS.AGREE',
      hideCancelButton: true,
      confirm: () => this.iAgree(),
    };

    this.modalService.addModal(new ConfirmationModal(data));
  }

  iAgree(): void {
    this.acceptTermsAndConditions.setValue(true);
    this.cdr.detectChanges();
  }

  enableButton(): void {
    this.disableButton = false;
    this.cdr.detectChanges();
  }
}
