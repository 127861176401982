import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { catchError, filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RegisterCandidatePayload } from 'src/app/models/candidate.model';
import { Observable, of, Subject } from 'rxjs';
import { IApplication } from 'src/app/models/application.model';
import { LoaderService } from 'src/app/services/loader.service';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { SetupService } from 'src/app/services/setup.service';
import { EnterpriseCompany, ICompany } from 'src/app/models/company.model';
import { AppConfigService } from 'src/app/services/app-config.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { checkForQueryParams } from 'src/app/shared-functions';
import { CompanyService } from 'src/app/services/company.service';
import { ConfirmationModalData } from 'src/app/models/modal.interface';
import { ConfirmationModal } from 'src/app/classes/modal.class';
import { Job } from 'src/app/models/job.model';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { TranslateService } from '@ngx-translate/core';
import { QuizService } from 'src/app/services/quiz.service';
import { ModalService } from 'src/app/services/modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Application } from 'src/app/classes/application.class';
import { Quiz } from 'src/app/models/quiz.model';
import { AbstractCandidateRegister } from '../abstract-candidate-register.class';
import { CandidateRegisterFormComponent } from '../candidate-register-form/candidate-register-form.component';

@Component({
  selector: 'app-register-regular-job',
  templateUrl: './register-regular-job.component.html',
  styleUrls: ['./register-regular-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterRegularJobComponent extends AbstractCandidateRegister implements OnInit, OnDestroy {
  @ViewChild(CandidateRegisterFormComponent) candidateRegisterFormComponent;

  multipleApplicationEligibleJobsList: Job[] = [];
  linkedinProfileValue: string;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    fb: FormBuilder,
    router: Router,
    toastr: ToastrService,
    translateService: TranslateService,
    loaderService: LoaderService,
    candidateRegisterService: CandidateRegisterService,
    private cdr: ChangeDetectorRef,
    private quizService: QuizService,
    private setupService: SetupService,
    private modalService: ModalService,
    private companyService: CompanyService,
    private configService: AppConfigService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    super(fb, router, toastr, loaderService, translateService, candidateRegisterService);

    this.router.events
      .pipe(
        filter((event) => event instanceof  NavigationStart),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({navigationTrigger}: NavigationStart) => {
        if (navigationTrigger === 'popstate') {
          sessionStorage.removeItem('answers');
          this.router.navigate(['home']);
        }
      });
  }

  get dataFromStorageExists(): boolean {
    const jobId = sessionStorage.getItem('jobId');
    const companyId = sessionStorage.getItem('companyId');

    if (this.configService.isAllJobs) {
      const companyDomain = sessionStorage.getItem('companyDomain');
      return !!jobId && !!companyId && !!companyDomain;
    }

    return this.configService.organization.isEnterprise
      ? (!!jobId && !!companyId)
      : !!jobId;
  }

  ngOnInit(): void {
    this.initializeRegularJobRegister();
    this.getAdditionalJobsToApplyTo();
  }

  getAdditionalJobsToApplyTo(): void {
    this.companyService.getJob().pipe(
      mergeMap((job: Job) => {
        this.linkedinProfileValue = job.linkedinProfile;
        this.cdr.detectChanges();
        if (job.boundedJobIds.length) {
          return this.companyService.getAdditionalJobsToApplyTo(job.boundedJobIds)
        } else {
          return of(null);
        }
      })
    ).subscribe((jobs: Job[]) => {
      if (jobs) {
        this.multipleApplicationEligibleJobsList = jobs
          .filter((job: Job) => job.status === 'active' && job.publishOnHigher === true)
          .map((job: Job) => {
            return { ...job, checked: false };
          });
      }
    }
    );
  }

  initializeRegularJobRegister(): void {
    this.checkIfJobIdExists();

    this.candidateRegisterForm.addControl('additionalJobsToApplyTo', this.fb.control([]));

    const { organization } = this.configService.config;

    if (organization.isAllJobs) {
      const storedCompanyDomain = sessionStorage.getItem('companyDomain');

      if (!storedCompanyDomain) {
        this.router.navigate(['/quiz'], { queryParamsHandling: 'merge' });
        return;
      }

      this.companyService.getCompanyByDomain(storedCompanyDomain)
        .subscribe((company: ICompany) => {
          this.termsAndConditions = company.termsAndConditions;
          this.companyGuid = company.guid;
        });

      return;
    }

    if (!organization.isEnterprise) {
      this.termsAndConditions = organization.termsAndConditions;
      return;
    }

    const companyId = +sessionStorage.getItem('companyId');
    const currentCompany = organization.companies
      .find((company: EnterpriseCompany) => company.id === companyId);

    this.termsAndConditions = currentCompany.termsAndConditions;
  }

  checkIfJobIdExists(): void {
    if (this.dataFromStorageExists) {
      return;
    }

    const { queryParams } = checkForQueryParams(decodeURIComponent(this.router.url));
    this.router.navigate(['/quiz'], { queryParams });
  }

  createApplication(): Observable<IApplication | Application> {
    return this.candidateRegisterService.checkIfCandidateAlreadyApplied(this.candidateData.id)
      .pipe(
        mergeMap((result: { guid: string }[]) => {
          if (result.length) {
            return this.setupService.getBaseApplicationInfo(result[0].guid);
          } else {
            return of(null);
          }
        }),
        switchMap((result: IApplication) => {
          if (result) {
            if (result.applicationComplete) {
              this.alreadyAppliedForJob();
              return of(null);
            } else {
              return this.registerApplication();
            }
          }

          this.openMultipleApplicationEligibleJobsModal();
          return of(null);
        })
      );
  }

  registerApplication(): Observable<Application> {
    this.loaderService.show();

    const { phone, email, networks, name, linkedinProfile, additionalJobsToApplyTo } = this.candidateRegisterForm.value;

    const payload: RegisterCandidatePayload = {
      candidate: this.candidateData,
      phone: networks.callingCode + phone,
      email,
      name,
      linkedinProfile,
      additionalJobsToApplyTo
    };

    if (this.multipleApplicationEligibleJobsList.length) {
      const checkedJobs = this.multipleApplicationEligibleJobsList.filter(job => job.checked);
      const additionalJobsToApplyTo = checkedJobs.map(job => job.id);
      payload.additionalJobsToApplyTo = additionalJobsToApplyTo;
    }

    const getKnockoutQuestions$ = this.dataFromStorageExists
      ? this.quizService.getInhouseAndKnockoutQuestions()
      : this.getKnockoutQuestionsForJob();

    return getKnockoutQuestions$
      .pipe(
        mergeMap((quiz: Quiz) => {
          if (!!quiz && this.checkIfAnswersAreInvalid(quiz)) {
            const { queryParams } = checkForQueryParams(decodeURIComponent(this.router.url));

            this.router.navigate(['/quiz'], { queryParams });
            return of(null);
          }

          return this.candidateRegisterService.registerApplication(payload);
        }),
        tap((application: Application) => {
          if (!application) {
            this.loaderService.hide();
            return;
          }

          const { jobApplication, jobInfo } = application;

          this.configService.config.jobApplication = jobApplication;
          this.configService.config.job = jobInfo;

          if (jobApplication.applicationComplete) {
            this.alreadyAppliedForJob();
            return;
          }

          const route = jobApplication.continueApplication
            ? 'quiz'
            : 'application-start';

          this.router.navigate(
            [route],
            { queryParams: {application: jobApplication.guid}}
          );

          this.cdr.detectChanges();
          this.loaderService.hide();
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      );
  }

  checkIfAnswersAreInvalid(quiz: Quiz): boolean {
    const answers = JSON.parse(sessionStorage.getItem('answers'));

    if (!answers) {
      return true;
    }

    if (answers.length !== quiz.questions.length) {
      return true;
    }

    let isInvalid = false;

    answers.forEach((answer, index) => {
      if (isNaN(answer) || answer < 0 || answer > quiz.questions[index].answers?.length - 1) {
        isInvalid = true;
      }
    });

    return isInvalid;
  }

  getKnockoutQuestionsForJob(): Observable<Quiz> {
    return this.companyService
      .getJob()
      .pipe(
        mergeMap(({id, company}: Job) => {
          sessionStorage.setItem('jobId', id.toString());

          const { isEnterprise, isAllJobs } = this.configService.organization;

          if (isEnterprise) {
            sessionStorage.setItem('companyId', company.id.toString());
          }

          if (isAllJobs) {
            sessionStorage.setItem('companyId', company.id.toString());
            sessionStorage.setItem('companyDomain', company.companyDomain);
            sessionStorage.setItem('jobCompanyLogo', company.companyLogo.toString());
            this.configService.setJobCompanyLogo(company.companyLogo);
          }

          return this.quizService.getInhouseAndKnockoutQuestions();
        })
      );
  }

  openMultipleApplicationEligibleJobsModal(): void {
    if (!this.multipleApplicationEligibleJobsList?.length) {
      this.registerApplication().subscribe();
      return;
    }

    const data: ConfirmationModalData = {
      title: 'REGISTER.MODAL_TITLE',
      content: 'REGISTER.MODAL_CONTENT',
      confirmBtnTitle: 'HEADER.APPLY_NOW',
      hideCancelButton: true,
      list: this.multipleApplicationEligibleJobsList,
      confirm: () => this.registerApplication().subscribe()
    };

    const newModal = new ConfirmationModal(data);
    this.modalService.addModal(newModal);
    this.modalService.modalClosed$
    .pipe(
      takeUntil(this._ngUnsubscribe$)
    ).subscribe(modal => {
      if (modal === newModal) {
        this.candidateRegisterFormComponent.enableButton();
      }
    })
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
